<template>
  <div class="qcRecord">
    <div class="publicSearch">
      <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
        <el-button-group>
          <el-button @click="getQcInfos" size="mini" icon="el-icon-search">查询</el-button>
          <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
        </el-button-group>
        <div>
          <span>关键字搜索</span>
          <el-input v-model="searchData.keyWords" size="mini" placeholder="名称/编码" clearable></el-input>
        </div>
        <div>
          <span>单号</span>
          <el-input v-model="searchData.asnBillNo" size="mini" placeholder="单号" clearable></el-input>
        </div>
        <div>
          <span>料号</span>
          <el-input v-model="searchData.itemCode" size="mini" placeholder="名称/编码" clearable></el-input>
        </div>
        <div>
          <span>批次</span>
          <el-input v-model="searchData.batchName" size="mini" placeholder="名称/编码" clearable></el-input>
        </div>
        <div>
          <span>检验类型</span>
          <!-- <el-select v-model="searchData.qcType" size="mini" filterable placeholder="检验类型" clearable>
            <el-option key="免检" label="免检" value="免检"> </el-option>
            <el-option key="抽检" label="抽检" value="抽检"> </el-option>
            <el-option key="全检" label="全检" value="全检"> </el-option>            
          </el-select> -->
          <el-select v-model="searchData.qcType" size="mini" filterable placeholder="检验类型" clearable>
            <el-option v-for="(item, index) in qcTypes" :key="index" :label="item.dictValue" :value="item.dictKey">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>状态</span>
          <el-select v-model="searchData.state" size="mini" filterable placeholder="请选择状态" clearable>
            <el-option value=0 label="待处理" style="color:#909399">待处理</el-option>
            <el-option value=9 label="检验合格" style="color:green">检验合格</el-option>
            <el-option value=-1 label="检验不合格" style="color:#F56C6C">检验不合格</el-option>
          </el-select>
        </div>
        <div>
          <span>仓库</span>
          <el-select v-model="searchData.wareCode" size="mini" filterable placeholder="请选择仓库" clearable>
            <el-option v-for="item in wares" :key="item.wareCode" :label="item.wareName"
              :value="item.wareCode">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>供应商</span>
          <el-select v-model="searchData.supplierCode" size="mini" filterable placeholder="供应商" clearable>
            <el-option v-for="item in suppliers" :key="item.supplierCode"
              :label="item.supplierName" :value="item.supplierCode">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>工厂</span>
          <el-select v-model="searchData.organizationCode" size="mini" filterable placeholder="请选择工厂" clearable>
            <el-option v-for="item in organizations" :key="item.organizationCode"
              :label="item.organizationName" :value="item.organizationCode">
            </el-option>
          </el-select>
        </div>
        <div>
          <span>ID</span>
          <el-input v-model="searchData.qcId" size="mini" placeholder="" clearable></el-input>
        </div>
      </div>
    </div>

    <div class="headerBtns">
      <el-button size="small" type="warning" icon="iconfont icon-qc" @click="printQcBill()"> 打印质检单
      </el-button>
    </div>

    <div>
      <el-table :max-height="clientHeight - 280" :row-style="$parent.tableRowStyle" highlight-current-row stripe border
        :header-cell-style="$parent.tableHeaderColor" v-loading="loading.masterTable" :data="qcRecords"
        @selection-change="qcRecordSelectionChange" style="width: 100%">
        <el-table-column fixed="left" type="selection" width="55">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="poNo" label="单号" width="160">
          <template slot-scope="scope">
            <div>ID:{{ scope.row.qcId }}</div>
            <div>单号：<span class="textBtn" @click="
              $router.push({
                name: 'asnBillDetail',
                params: scope.row,
              })">{{ scope.row.asnBillNo }}</span></div>
            <div>{{ scope.row.poNo }}</div>
            <div>打印次数：{{ scope.row.printCount }}</div>
            <div>检验类型：
              <!-- <span v-if="scope.row.qcType == '全检'" style="color:#F56C6C">全检</span>
              <span v-if="scope.row.qcType == '抽检'" style="color:#E6A23C">抽检</span>
              <span v-if="scope.row.qcType == '免检'" style="color:#67C23A">免检</span> -->
              <span v-for="(item, index) in qcTypes" :key="index" v-if="item.dictKey == scope.row.qcType">{{
                item.dictValue }}</span>
            </div>
            <div>检验状态：
              <span v-for="item in qcAllResult" v-show="scope.row.state == item.value" :style="item.style"
                :label="item.label" :value="item.value">{{ item.label }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip prop="batchName" label="送检信息" width="240">
          <template slot-scope="scope">
            <div>容器编码：{{ scope.row.containerNo }}</div>
            <div>批次：{{ scope.row.batchName }}</div>
            <div>送检数：{{ scope.row.dhQuantity }}{{ scope.row.packName }}</div>
            <div>送检人：{{ scope.row.createUserName }}</div>
            <div>送检时间：{{ scope.row.createTime }}</div>
          </template>

        </el-table-column>

        <el-table-column show-overflow-tooltip prop="itemCode" label="物料/供应商" width="280">
          <template slot-scope="scope">
            <div>物料编码：{{ scope.row.itemCode }}</div>
            <div>名称/型号：{{ scope.row.itemDesc }}</div>
            <div>采购数量：{{ scope.row.quantity }} {{ scope.row.packName }}</div>
            <div>供应商编码：{{ scope.row.supplierCode }}</div>
            <div>供应商：{{ scope.row.supplierName }}</div>
          </template>

        </el-table-column>

        <el-table-column show-overflow-tooltip prop="detailMemo" label="检验备注" min-width="180">
          <template slot-scope="scope">
            <div>质量浓度(%)：{{ scope.row.quality }}</div>
            <div>检验人：{{ scope.row.modifyUserName }}</div>
            <div>检验时间：{{ scope.row.modifyTime }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="memo" label="结果备注" min-width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.state != 0" @click="qcInfoDo(scope.row)" type="primary"
              size="small">检验</el-button>
            <el-button v-if="scope.row.image != null && scope.row.image != ''" @click="getImgsPlayBackData(scope.row)"
              type="warning" size="mini">单据</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination class="pagination-bottom" style="text-align: right;" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" :current-page.sync="searchData.pageNum" :page-sizes="[15, 30, 50, 100]"
        :page-size="searchData.pageSize" layout="sizes,total, prev, pager, next" :total="searchData.total">
      </el-pagination>
    </div>

    <div style="display: none;">
      <div id="printQcBill" style="width:760px;">
        <div style="text-align:center;font-weight:bold;font-size: 30px;margin-top: 10px;">送检单</div>
        <div style="text-align:right;margin-right: 50px;margin-top:10px">送检时间：{{ localTime }}</div>
        <div style="margin-top:10px">
        </div>
        <table style="width:760px;border-collapse: collapse;margin-top: 10px;" border="1">
          <tr>
            <!-- <th>ID</th> -->
            <th>单号</th>
            <th>物料</th>
            <!-- <th>物料描述</th> -->
            <th>质检类型</th>
            <th>采购数</th>
            <th>送检数</th>
            <th>批号</th>
            <th>仓库</th>
            <th>备注</th>
            <th>状态</th>
            <th>送检时间</th>
            <th>送检人</th>
            <!-- <th>事业部</th> -->
            <th>供应商</th>
            <!-- <th>备注</th> -->
          </tr>
          <tr v-bind:key="asnDetail.asnDetailId" v-for="asnDetail in printQcRecords">
            <!-- <td>{{ asnDetail.qcId }}</td> -->
            <td>{{ asnDetail.asnBillNo }}</td>
            <td>
              {{ asnDetail.itemCode }}
              <br>
              {{ asnDetail.itemName }}

            </td>
            <!-- <td>{{ asnDetail.itemName }}</td> -->
            <!-- <td>{{ asnDetail.qcType }}</td> -->
            <td v-for="(item, index) in qcTypes" :key="index" v-if="item.dictKey == asnDetail.qcType">{{ item.dictValue }}
            </td>
            <td>{{ asnDetail.quantity }}</td>
            <td>{{ asnDetail.dhQuantity }}</td>
            <td>{{ asnDetail.batchName }}</td>
            <!-- <td>新：{{ asnDetail.batchName }}<br>
            旧：{{ asnDetail.oldBatchName }}
          </td> -->
            <td>
              {{ asnDetail.wareCode }}

              {{ asnDetail.wareName }}
            </td>
            <td> {{ asnDetail.memo }}</td>
            <td v-for="(item, index) in qcAllResult" :key="index" v-if="item.value == asnDetail.state"> {{ item.label }}
            </td>
            <td> {{ asnDetail.createTime }}</td>
            <td> {{ asnDetail.createUserName }}</td>
            <!-- <td> {{ asnDetail.syb }}</td> -->
            <td> {{ asnDetail.supplierName }}</td>
            <!-- <td> {{ asnDetail.detailMemo }}</td> -->
          </tr>
          <tr>
            <td colspan="3">数量合计：</td>
            <td>{{ quantityTotal }}</td>
            <td>{{ dhQuantityTotal }}</td>
            <td colspan="4"></td>
          </tr>
        </table>
        <div style="margin-top:10px">
          <div style="width:33%;float: left;">IQC质检员</div>
          <div style="width:33%;float: left;">审核员：</div>
          <div style="width:33%;float: left;">库管员：</div>
        </div>
      </div>
    </div>

    <el-dialog title="检验" :visible.sync="qcDo" width="800px" style="margin-top: -10vh;">
      <el-form :model="qcForm" status-icon ref="qcForm" label-width="100px" class="demo-ruleForm">
        <el-input v-model="qcForm.containerId" style="width: 93%;display: none;"></el-input>
        <el-row>
          <el-form-item label="物料名" prop="itemCode">
            {{ qcForm.itemDesc }}
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="13">
            <el-form-item label="物料编码" prop="itemCode">
              {{ qcForm.itemCode }}
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="容器编号" prop="containerNo">
              {{ qcForm.containerNo }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="13">
            <el-form-item label="浓度" prop="quality">
              <el-input v-model="qcForm.quality" placeholder="请输入浓度(%)"></el-input><span>%</span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="检验结果" prop="detailMemo">
              <el-select v-model="qcForm.state" size="mini" filterable placeholder="请选择状态" clearable>
                <el-option v-for="(item, index) in qcAllResult" :key="index" :style="item.style" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="上传单据" prop="memo">
            <div>
              <UploadImg :limit="3" :lableName="'上传单据'" :upUrl="upUrl + '?type=' + typeKey" :rtUrl="rtUrl + rlPath"
                :propsList='qcForm.image' @updateImg="updateImg" />
            </div>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="结果描述" prop="memo">
            <el-input type="textarea" rows="3" v-model="qcForm.memo" placeholder="请输入结果描述"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qcDo = false">取 消</el-button>
        <el-button type="success" @click="commit()">提交</el-button>
      </div>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="imageVisible" width="800px" @close="closeDialogimgs">
      <el-carousel indicator-position="outside" :interval="2000" :height="imgHeight + 'px'">
        <el-carousel-item v-for="(item, index) in imgUrls" :key="index">
          <img ref="img" :src="item" class="s-img" mode="widthFix" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UploadImg from '@/components/UploadImage'

export default {
  name: 'QcRecord',
  components: {
    UploadImg,
  },
  data() {
    return {
      //文件上传的数组
      fileList: [],
      qcDo: false,
      isMoreSearch: false,
      organizationId: PF.getLocal('organizationId'),
      organizationCode: PF.getLocal('organizationCode'),
      typeKey: 'qcPathKey',
      upUrl: BASEIP + '/upload/files',
      rtUrl: BASEIP + "/file/files",
      rlPath: '',
      searchData: {
        pageNum: 1,
        pageSize: 10,
        total: null,
        keyWords: '',
        state: null,
        asnBillNo: '',
        itemCode: '',
        batchName: '',
        wareCode: '',
        supplierCode: '',
        qcType: '',
        organizationCode: PF.getLocal('organizationCode'),
        billType: null,
        qcId: null,
      },
      wares: [],
      qcRecords: [],
      printQcRecords: [],
      rows: [],
      spans: [],
      suppliers: [],
      organizations: [],
      qcForm: {},

      formLabelWidth: '120px',

      multipleSelection: [],
      qcUdfDs: [],
      localTime: '',
      dialogImageUrl: '',
      dialogImageVisible: false,

      imageVisible: false,
      imgUrls: '',

      imgHeight: '',

      loading: {
        masterTable: true,
      },
      clientHeight: $(window).height(),
    }
  },
  computed: {
    ...mapState([
      "reviewStates",
      "asnStates",
      "qcAllResult",
      "qcTypes",
    ]),

    dhQuantityTotal() {
      var quantity = 0;
      for (var i = 0; i < this.printQcRecords.length; i++) {
        quantity += this.printQcRecords[i].dhQuantity
      }
      return quantity;
    },

    quantityTotal() {
      var quantity = 0;
      for (var i = 0; i < this.printQcRecords.length; i++) {
        quantity += this.printQcRecords[i].quantity
      }
      return quantity;
    }
  },
  watch: {
  },
  methods: {
    printQcBill() {
      var that = this;

      // var div=document.getElementById('printQcBill');
      // div.style.setProperty('display','block');
      // var htmlStr =$('#printQcBill').prop('outerHTML');
      // div.style.setProperty('display','none');
      // this.$parent.printHtml(htmlStr, 1);  

      var div = document.getElementById('printQcBill');
      $("#printQcBill").printArea();

      for (var i = 0; i < that.printQcRecords.length; i++) {
        var id = that.printQcRecords[i].qcId;
        IOT.getServerData("/qc/infos/printCount", 'get', { id: id }, (ret) => {
          that.getQcInfos();
        }, true);
      }
    },
    qcRecordSelectionChange(val) {
      this.printQcRecords = val;
    },

    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.getQcInfos()
    },
    handleCurrentChange(val) {
      this.searchData.pageNum = val;
      this.getQcInfos()
    },
    clearSearchData() {
      this.searchData.keyWords = '';
      this.searchData.keyWords = '';
      this.searchData.asnBillNo = '';
      this.searchData.itemCode = '';
      this.searchData.batchName = '';
      this.searchData.wareCode = '';
      this.searchData.supplierCode = '';
      this.searchData.qcType = '';
      this.searchData.state = null;
      this.searchData.qcId = null;
      this.organizationCode = PF.getLocal('organizationCode');
    },
    //图片列表转换
    updateImg(imgList) {
      let img = "";
      imgList.forEach(element => {
        img += "," + element.url;
      });
      if (img.length > 0) img = img.substring(1);
      this.qcForm.image = img;
    },
    getQcInfos() {
      debugger
      let that = this;
      that.loading.masterTable = true;
      IOT.getServerData('/qc/infos/list', 'get', this.searchData, (ret) => {
        that.loading.masterTable = false;
        if (ret.code === 200) {
          let list = ret.rows;
          this.qcRecords = list;
          this.searchData.total = ret.total;
          this.searchData.pageNum = ret.pageNumber;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    qcInfoDo(qcInfo) {
      this.qcDo = true;
      this.qcForm = PF.JSON(qcInfo);
    },
    commit() {
      if (!this.qcForm.state) {
        IOT.tips('必须选择检验结果', 'error');
        return false;
      }
      // if (!this.qcForm.memo) {
      //   IOT.tips('必须填写结果描述', 'error');
      //   return false;
      // }
      this.qcDo = false;
      IOT.showOverlay('保存中，请稍等...');
      IOT.getServerData('/qc/infos/update', 'post', this.qcForm, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          this.getQcInfos();
          IOT.tips('检验结果提交成功！', 'success');
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // getWareData() {
    //   IOT.getServerData('/ware/infos/findSome', 'get', {
    //     organizationCode: this.organizationCode
    //   }, (ret) => {
    //     if (ret.code === 200) {
    //       this.wares = ret.rows;
    //     } else {
    //       IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
    //     }
    //   });
    // },
    getImgsPlayBackData(row) {
      let imgs = row.image.split(',');
      for (var i = 0; i < imgs.length; i++) {
        imgs[i] = this.rtUrl + this.rlPath + imgs[i];
      }
      this.previewimg(imgs);
    },
    // 图片
    previewimg(imgs) {
      console.log(imgs);
      this.imgUrls = imgs;
      this.imageVisible = true;
    },
    closeDialogimgs() {
      this.imgUrls = [];
    },
    get_banner() {
      this.bannerList = []
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1400,height=520 图片的默认宽高
        this.imgHeight = document.body.clientWidth * 520 / 1400
      });
    },
  },
  created() {
    PF.getRelativePath(this.typeKey, (path) => {
      this.rlPath = path;
    });
    PF.getOrganizations((rows) => {
      this.organizations = rows;
    });
    PF.getSuppliers((rows) => {
      this.suppliers = rows;
    });
    PF.getWareData(this.organizationCode, (rows) => {
      this.wares = rows;
    });
    // this.getWareData();
    this.getQcInfos();

    var time = new Date();
    this.localTime = time.toLocaleString()
  },
  destroyed() { //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
  mounted() {
    var that = this;
    this.get_banner();
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", () => {
      that.get_banner();
    }, false);
  },
}
</script>

<style scoped lang="less">
.qcRecord {}
</style>
